<script>
/**
 * Stat component -- specify the widget icon, title and value.
 */
export default {
  props: {
    refid: {
      type: String,
      default: "imageUpload"
    },
    imgDefault: {
      type: String,
      default: ""
    },
    onChangeImage: { type: Function },

  },
  data() {
    return {
      image: null,
      preview : null,
    };
  },
  methods:{
    previewImage: function(event) {
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        }
        this.image=input.files[0];
        this.onChangeImage(input.files[0])
        reader.readAsDataURL(input.files[0]);
      }
    },
    ChangeImage(){
        this.$refs[this.refid].click()
        // console.log(this.$refs[this.refid])
    }
  }
};
</script>

<template>
  <div class="card mini-stats-wid min-h-300 parent">
      <input
      :ref="refid"
      type="file" 
      hidden 
      @change="previewImage">
      <img v-if="preview == null" :src="imgDefault" alt="" width="100%">
      <img v-else :src="preview" alt="" width="100%">
      <button @click="ChangeImage()" class="btn btn-circle btn-warning btn-image-edit"><i class="bx bx-edit font-size-16"></i></button>
  </div>
  <!-- end card -->
</template>
<style>
.btn-image-edit{
    border-radius: 50% !important;
    width: fit-content !important;
    text-align: center !important;
    border: 1.5px white !important;
    position: absolute !important;
    right: 10px !important;
    top: 10px !important;
    padding: 12px !important;
}
.min-h-300{
    min-height: 300px !important;
    max-height: 300px !important;
}
.parent {
  height: 200px; /* Set the desired height of the parent div */
  position: relative;
  overflow: hidden;
}

.parent img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
</style>