/* eslint-disable vue/no-side-effects-in-computed-properties */
<script>
import Layout from "../layouts/main";
import appConfig from "@/app.config";
import ImageUpload from "../../components/widgets/ImageUpload.vue"
import Swal from "sweetalert2";
/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Setting",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout,ImageUpload },
  data() {
    return {
      title: "Setting",
      logo : null,
      favicon : null,
      thumbnail : null,
      logoPriview : "",
      faviconPriview : "",
      thumbnailPriview : "",
      app_name:"",
      slogan:"",
      id : 0
      
    }
  },
  computed: {
    item() { return this.$store.getters['setting/item'] },
  },
  mounted(){
    this.$store.dispatch("setting/get").then((item)=>{
      this.app_name = item.app_name
      this.slogan = item.slogan
      this.logoPriview = `https://admin-api.peanutbutterevents.com/storage/images/${item.logo}`
      this.faviconPriview = `https://admin-api.peanutbutterevents.com/storage/images/${item.favicon}`
      this.thumbnailPriview = `https://admin-api.peanutbutterevents.com/storage/images/${item.thumbnail}`
      this.id = item.id
    })
  },
  methods:{
    save(){
      let formdata = new FormData();
      if(this.logo != null){
        formdata.append('logo',this.logo)
      }
      if(this.favicon != null){
        formdata.append('favicon',this.favicon)
      }
      if(this.thumbnail != null){
        formdata.append('thumbnail',this.thumbnail)
      }
      formdata.append('app_name',this.app_name)
      formdata.append('slogan',this.slogan)
      formdata.append('id',this.id)
      this.$store.dispatch("setting/create",formdata).then((item)=>{
          this.app_name = item.app_name
          this.slogan = item.slogan
          this.logoPriview = `https://admin-api.peanutbutterevents.com/storage/images/${item.logo}`
          this.faviconPriview = `https://admin-api.peanutbutterevents.com/storage/images/${item.favicon}`
          this.thumbnailPriview = `https://admin-api.peanutbutterevents.com/storage/images/${item.thumbnail}`
          this.id = item.id
          Swal.fire("Saved!", "Data berhasil terupdate", "success");
      })
    }
  }

};
</script>

<template>
  <Layout>
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0 font-size-18">Settings</h4>
          <div class="page-title-right">
          </div>
        </div>
      </div>
      <div class="col-12 p-6" v-if="item != null">
          <div class="row mb-6">
              <div class="col-md-6">
                  <label class="control-label">Apps Name : </label>
                  <input type="text" class="form-control" v-model="app_name">
              </div>
              <div class="col-md-6">
                  <label class="control-label">Slogan : </label>
                  <input type="text" class="form-control" v-model="slogan">
              </div>
          </div>
          <br>
          <div class="row">
              <div class="col-md-4">
                  <label class="control-label">Logo : </label>
                  <ImageUpload :refid="`logo`" :onChangeImage="(image)=>this.logo=image" :imgDefault="logoPriview"/>
              </div>
              <div class="col-md-4">
                  <label class="control-label">Favicon : </label>
                  <ImageUpload :refid="`favicon`" :onChangeImage="(image)=>this.favicon=image" :imgDefault="faviconPriview"/>
              </div>
              <div class="col-md-4">
                  <label class="control-label">Thumbnail : </label>
                  <ImageUpload :refid="`thumbnail`" :onChangeImage="(image)=>this.thumbnail=image" :imgDefault="thumbnailPriview"/>
              </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <button class="btn btn-success" @click="save()">Save</button>
            </div>
          </div>
      </div>
    </div>
  </Layout>
</template>
